@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  display: flex;
  align-items: center;
}
:root {
  /* Background colors */
  --bg-primary: #ffffff;
  --bg-secondary: #f6f7f9;
  --bg-tertiary: #f9fafb;

  /* Text colors */
  --text-primary: #0d0d0d;
  --text-secondary: #344054;
  --text-tertiary: #424e62;

  /* Border colors */
  --border-primary: #d0d5dd;
  --border-secondary: #e4e7ec;
  --border-tertiary: #f2f4f7;

  /* Accent color */
  --accent: 75, 99%, 56%;

  /* Button colors */
  --button-primary-bg: #0d0d0d;
  --button-primary-bg-hover: #c6fe1e;
  --button-primary-text: #ffffff;
  --button-primary-fg-hover: #0d0d0d;

  --button-secondary-bg: #f6f7f9;
  --button-secondary-bg-hover: #eceef2;
  --button-secondary-text: #344054;
  --button-secondary-text-hover: #424e62;

  --button-clink: #0a4ceb;
  --button-clink-hover: #0f3ebe;

  /* Input autocomplete colors */
  --input-autofill-bg: #ffffff;
  --input-autofill-text: #0d0d0d;
  --input-autofill-border: #d0d5dd;
}

.dark {
  /* Background colors for dark mode */
  --bg-primary: #0d0d0d;
  --bg-secondary: #1b1b1b;
  --bg-tertiary: #1b1b1b;

  /* Text colors for dark mode */
  --text-primary: #ffffff;
  --text-secondary: #909090;
  --text-tertiary: #909090;

  /* Border colors for dark mode */
  --border-primary: #323232;
  --border-secondary: #1f2023;
  --border-tertiary: #1f2023;

  /* Accent color */
  --accent: 75, 99%, 56%;

  /* Button colors for dark mode */
  --button-primary-bg: #ffffff;
  --button-primary-bg-hover: #c6fe1e;
  --button-primary-text: #ffffff;
  --button-primary-fg-hover: #0d0d0d;

  --button-secondary-bg: #1b1b1b;
  --button-secondary-bg-hover: #0d0d0d;
  --button-secondary-text: #909090;
  --button-secondary-text-hover: #ffffff;

  --button-clink: #ffffff;
  --button-clink-hover: #ffffff;

  /* Input autocomplete colors for dark mode */
  --input-autofill-bg: #1b1b1b;
  --input-autofill-text: #ffffff;
  --input-autofill-border: #323232;
}

body {
  font-family: "Inter", sans-serif;
  background-color: white;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: Poppins;
  transition: background-color 600ms linear
}

.scrollable-element {
  scrollbar-width: thin;
  scroll-padding: 10px;
}

form {
  width: 30vw;
  /* min-width: 500px; */
  align-self: center;
  border-radius: 7px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  /* margin-bottom: 24px; */
}

/* Buttons and links */
button {
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

.bopanna {
  background: red;
}

.editor {
  position: absolute;
  padding: 50px;
  left: 0;
  top: 0;
}

.menuHeader {
  color: #aaa7a7;
  padding-top: 30px;
  padding-right: 10px;
  font-size: large;
  font-weight: 700;
  padding-bottom: 10px;
}

.layout {
  position: absolute;
  padding: 50px;
  left: 10%;
  top: 6%;
}

.dialog {
  position: absolute;
  padding: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  right: 0;
  top: 0;
}

.cardtest {
  column-gap: 10px;
}

@media screen and (max-width:728px) {

  .editor,
  .layout,
  .dialog {
    display: none;
  }
}



.checkmark_ok {
  position: absolute;
  animation: grow 1.4s cubic-bezier(0.42, 0, 0.275, 1.155) both;
}

.checkmark_ok:nth-child(1) {
  width: 12px;
  height: 12px;
  left: 12px;
  top: 16px;
}

.checkmark_ok:nth-child(2) {
  width: 18px;
  height: 18px;
  left: 168px;
  top: 84px;
}

.checkmark_ok:nth-child(3) {
  width: 10px;
  height: 10px;
  left: 32px;
  top: 162px;
}

.checkmark_ok:nth-child(4) {
  width: 20px;
  height: 20px;
  left: 82px;
  top: -12px;
}

.checkmark_ok:nth-child(5) {
  width: 14px;
  height: 14px;
  left: 125px;
  top: 162px;
}

.checkmark_ok:nth-child(6) {
  width: 10px;
  height: 10px;
  left: 16px;
  top: 16px;
}

.checkmark_ok:nth-child(1) {
  animation-delay: 1.7s;
}

.checkmark_ok:nth-child(2) {
  animation-delay: 1.4s;
}

.checkmark_ok:nth-child(3) {
  animation-delay: 2.1s;
}

.checkmark_ok:nth-child(4) {
  animation-delay: 2.8s;
}

.checkmark_ok:nth-child(5) {
  animation-delay: 3.5s;
}

.checkmark_ok:nth-child(6) {
  animation-delay: 4.2s;
}

.checkmark {
  position: relative;
  padding: 30px;
  /*   animation: checkmark 5.6s cubic-bezier(0.42, 0, 0.275, 1.155) both; */
  animation: checkmark 10s cubic-bezier(0.42, 0, 0.275, 1.155) both infinite;
}

.checkmark__check {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate3d(-50%, -50%, 0);
  fill: #fff;
}

.checkmark__back {
  animation: rotate 35s linear both infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes grow {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes checkmark {

  0%,
  100% {
    opacity: 0;
    transform: scale(0);
  }

  10%,
  50%,
  90% {
    opacity: 1;
    transform: scale(1);
  }
}

#go_back {
  font-weight: 400;
  padding-top: 20px;
  text-decoration: underline;
  cursor: pointer;
  text-underline-position: under;
  color: currentColor;
}

/* Update the autofill styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--input-autofill-text) !important;
  -webkit-box-shadow: 0 0 0 30px var(--input-autofill-bg) inset !important;
  box-shadow: 0 0 0 30px var(--input-autofill-bg) inset !important;
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s !important;
  caret-color: var(--input-autofill-text) !important;
  border-color: var(--input-autofill-border) !important;
}

.button-primary {
  @apply w-full flex items-center justify-center py-2 px-4 font-normal 
  hover:text-black border border-transparent hover:border-border-secondary 
  mt-6 bg-button-primary-bg dark:text-black font-display text-white 
  rounded-lg shadow-sm hover:bg-accent focus:outline-none 
  focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;
}

.button-outline {
  @apply w-fit flex items-center justify-center py-2 px-4 font-normal 
 border-2 border-border-primary hover:border-border-secondary 
  mt-6 bg-button-secondary-bg dark:text-white font-display text-black 
  rounded-lg shadow-sm hover:bg-button-secondary-hover focus:outline-none 
  focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;
}
.button-secondary {
  @apply flex items-center justify-center py-2 px-4 bg-button-secondary-bg font-display font-normal rounded-lg shadow-sm hover:bg-button-secondary-hover text-button-secondary-text dark:text-white hover:text-button-secondary-text-hover text-sm;
}