.form-input {
  @apply w-full px-3 text-sm placeholder:text-[#667085] dark:placeholder:text-[#888888] text-text-primary py-2 bg-background-primary border border-border-primary rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-accent focus:border-accent disabled:cursor-not-allowed disabled:opacity-60 ;
}

.form-select {
  @apply w-full z-10 px-3 py-2 border placeholder:text-text-tertiary text-text-primary border-border-primary rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-accent focus:border-accent bg-background-primary;
}

.form-label {
  @apply block text-sm font-normal text-text-secondary mb-1;
}
 